dp-calendar-nav {
  .dp-calendar-nav-container {
    @apply mt-2 h-9 border-0 #{!important};
  }
  .dp-nav-header {
    @apply absolute left-1/2 top-1/2 z-50 block -translate-x-1/2 -translate-y-1/2 #{!important};
  }
  .dp-nav-header-btn {
    @apply appearance-none rounded-md border-0 bg-transparent p-1 text-base font-semibold text-gray-900 #{!important};
  }
  .dp-nav-btns-container {
    @apply relative top-0 right-auto flex h-full w-full transform-none justify-between #{!important};
  }
  .dp-calendar-nav-container-left,
  .dp-calendar-nav-container-right {
    @apply relative flex h-8 w-8 touch-manipulation items-center justify-center #{!important};
    @extend .btn-nav;
  }
  .dp-calendar-nav-left,
  .dp-calendar-nav-right {
    @apply touch-manipulation appearance-none border-0 bg-transparent text-sm before:h-3 before:w-3 before:transform-none before:border-0 before:text-gray-900 #{!important};
  }
  .dp-calendar-nav-container-left {
    @apply ml-5 #{!important};
  }
  .dp-calendar-nav-container-right {
    @apply mr-5 #{!important};
  }
  .dp-calendar-nav-left {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIGFyaWEtaGlkZGVuPSJ0cnVlIiBkYXRhLXNsb3Q9Imljb24iIHN0eWxlPSJ3aWR0aDp2YXIoLS1uZy1pY29uX19zaXplLCAxZW0pO2hlaWdodDp2YXIoLS1uZy1pY29uX19zaXplLCAxZW0pO3N0cm9rZS13aWR0aDp2YXIoLS1uZy1pY29uX19zdHJva2Utd2lkdGgsIDEuNSkiPjxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTEwLjUgMTkuNSAzIDEybTAgMCA3LjUtNy41TTMgMTJoMTgiPjwvcGF0aD48L3N2Zz4=);
    @apply h-full w-full bg-[length:24px_24px] bg-center bg-no-repeat before:hidden #{!important};
  }
  .dp-calendar-nav-right {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIGFyaWEtaGlkZGVuPSJ0cnVlIiBkYXRhLXNsb3Q9Imljb24iIHN0eWxlPSJ3aWR0aDp2YXIoLS1uZy1pY29uX19zaXplLCAxZW0pO2hlaWdodDp2YXIoLS1uZy1pY29uX19zaXplLCAxZW0pO3N0cm9rZS13aWR0aDp2YXIoLS1uZy1pY29uX19zdHJva2Utd2lkdGgsIDEuNSkiPjxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTEzLjUgNC41IDIxIDEybTAgMC03LjUgNy41TTIxIDEySDMiPjwvcGF0aD48L3N2Zz4=);
    @apply h-full w-full bg-[length:24px_24px] bg-center bg-no-repeat before:hidden #{!important};
  }
  .dp-current-location-btn {
    @apply hidden  #{!important};
  }
}

dp-month-calendar {
  @apply block #{!important};
  .dp-months-row {
    @apply flex justify-between #{!important};
  }
}

dp-day-calendar {
  @apply block #{!important};
  .dp-calendar-wrapper {
    @apply border-0 p-4 #{!important};
  }
  .dp-day-calendar-container {
    @apply bg-transparent #{!important};
  }
  .dp-calendar-month {
    @apply h-14 w-14 #{!important};
  }
  .dp-calendar-day,
  .dp-calendar-month {
    @apply mt-2 appearance-none rounded-full border-0 bg-transparent text-sm text-gray-900 hover:bg-gray-100 #{!important};
  }
  .dp-calendar-day {
    @apply h-10 w-10 #{!important};
  }
  .dp-calendar-day[disabled],
  .dp-calendar-month[disabled] {
    @apply pointer-events-none cursor-default opacity-40 hover:bg-transparent #{!important};
  }
  .dp-calendar-week {
    @apply flex justify-between #{!important};
  }
  .dp-weekdays {
    @apply flex justify-between #{!important};
  }
  .dp-calendar-weekday {
    @apply w-[40px] border-0 bg-transparent text-xs text-gray-900 #{!important};
  }
  .dp-calendar-month.dp-current-month,
  .dp-calendar-day.dp-current-day {
    @apply rounded-full bg-gray-100 hover:bg-gray-100 #{!important};
  }
  .dp-calendar-day.dp-selected {
    @apply bg-primary hover:bg-primary rounded-full text-white #{!important};
  }
}

.dp-popup {
  @apply cursor-default rounded-md border-0 border-gray-300 bg-white shadow-lg #{!important};
}
