@import "./animations";

// Needed for hero icons as the actual svg element is a child of the svg-icon component
@for $i from 1 through 5 {
  .stroke-#{$i} {
    > svg {
      stroke-width: #{$i}px;
    }
  }
}

.loading-ellipsis:after {
  @apply animate-ellipsis inline-block w-0 overflow-hidden align-bottom;
  content: "\2026";
}

.centred-container {
  @apply block w-full bg-gray-100 py-10 px-4 sm:px-8;
}

// Typing indicator
.typing {
  font-size: 0;
  animation: fadeInUp 200ms linear 1 both;

  .typing__bullet {
    animation: bounce 600ms linear infinite both;
    transition: all 300ms linear;

    &:nth-child(2) {
      animation-delay: 150ms;
    }

    &:nth-child(3) {
      animation-delay: 300ms;
    }

    &:not(:last-child) {
      @apply mr-1;
    }
  }
}

.disabled {
  @include disabled();
}

.label-reset {
  @apply font-inherit mb-0 text-inherit;
}
